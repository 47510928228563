export default [
  {
    key: 'ccy',
    label: 'field.ccy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'currency',
    selectionKey: 'ccy',
    selectionLabel: 'currency',
  },
  {
    key: 'userIds',
    label: 'breadcrumb.users',
    rules: '',
    type: 'nAsynMultiSelection',
    repository: 'member',
    selectionKey: 'id',
    selectionLabel: 'username',
  },
  {
    label: 'breadcrumb.channel1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTime',
    label: 'field.minBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTime',
    label: 'field.maxBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGame',
    label: 'field.maxBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxDrawBetGame',
    label: 'field.maxDrawBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDay',
    label: 'field.maxWinDay',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.channel2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeTwo',
    label: 'field.minBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeTwo',
    label: 'field.maxBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameTwo',
    label: 'field.maxBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameTwo',
    label: 'field.maxDrawBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayTwo',
    label: 'field.maxWinDay',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.baccarat1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeBacar',
    label: 'field.minBetTimeBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeBacar',
    label: 'field.maxBetTimeBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameBacar',
    label: 'field.maxBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameBacar',
    label: 'field.maxDrawBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayBacar',
    label: 'field.maxWinDayBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.baccarat2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeBacarTwo',
    label: 'field.minBetTimeBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeBacarTwo',
    label: 'field.maxBetTimeBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameBacarTwo',
    label: 'field.maxBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameBacarTwo',
    label: 'field.maxDrawBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayBacarTwo',
    label: 'field.maxWinDayBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
]
