<template>
  <span v-if="$can('read', 'disable-post')">
    <b-button
      variant="primary"
      pill
      size="sm"
      @click="show"
      class="ml-50"
      v-b-tooltip.hover
      :title="$t('button.closeChannel')"
    >
      <feather-icon icon="XIcon" />
    </b-button>
    <b-modal
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      v-model="isShow"
      :title="$t('button.closeChannel')"
    >
      <validation-observer ref="form" #default="{ invalid }">
        <n-form-confirmation
          ref="confirmForm"
          key="form"
          @submit="save"
          :form="$refs.form"
          :disabled="invalid"
        >
          <b-row
            v-for="(lottery, idx) in lotteries"
            :key="`lottery-${idx}`"
            class="dynamic-group"
          >
            <b-col cols="12">
              <h4>
                {{ $i18n.locale == "en" ? lottery.name : lottery.nameKh }}
              </h4>
            </b-col>
            <b-col
              cols="12"
              v-for="(time, j) in lottery.times"
              :key="`time-${lottery.id}-${time.id}`"
            >
              <hr class="lottery-saperate-line" v-if="j > 0" />
              <b-form-group>
                <label>
                  {{
                    moment(time.name, "HH:mm")
                      .locale($i18n.locale)
                      .format("h:mm a")
                  }}
                </label>
                <b-form-checkbox-group
                  v-model="time.postClose"
                  :id="`post-${lottery.id}-${time.id}`"
                  :name="`post-${lottery.id}-${time.id}`"
                >
                  <b-form-checkbox
                    :id="`post-${lottery.id}-${time.id}-${post.id}`"
                    :name="`post-${lottery.id}-${time.id}-${post.id}`"
                    :value="post.post"
                    v-for="post in time.posts"
                    :key="`post-${lottery.id}-${time.id}-${post.id}`"
                  >
                    {{ post.post }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="$can('update', 'disable-post')">
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="button"
                variant="primary"
                :loading="loading"
                @submit="submit"
              >
                {{ $t("button.save") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </span>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading.vue";
import FormInput from "./formInput";
import Repository from "@/repositories/RepositoryFactory";
import moment from "moment";

const LotteryRepository = Repository.get("lottery");
const MemberRepository = Repository.get("member");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormCheckboxGroup,
    BFormGroup,
    BFormCheckbox,
    NInput,
    NFormConfirmation,
    NButtonLoading,
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      lotteries: [],
      moment,
    };
  },
  methods: {
    getClosePost() {
      if (!this.userId) {
        return;
      }
      LotteryRepository.getClosePost({
        userId: this.userId,
      }).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
        }
      });
    },
    show() {
      if (!this.userId) {
        return;
      }
      this.lotteries = [];
      this.getClosePost();
      this.isShow = true;
      this.key++;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    stopLoading() {
      this.loading = false;
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      if (!this.userId) {
        return;
      }
      this.loading = true;
      let data = {
        userId: this.userId,
        lotteries: [],
      };
      this.lotteries.forEach((lottery) => {
        lottery.times.forEach((time) => {
          if (time.postClose.length) {
            data.lotteries.push({
              lotteryId: lottery.id,
              lotteryTimeId: time.id,
              postClose: time.postClose,
            });
          }
        });
      });
      MemberRepository.saveClosePost(data)
        .then((response) => {
          this.hide();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.form.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;
    return { fields };
  },
};
</script>